import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const CreditPage = () => {
  return (
    <Layout hero={<HeroImage title="Credit Application" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row mt-4">
        <Col sm={10}>
          <p>If you are interested in opening a Commercial Credit account with Orrcon Steel, 
            please proceed to our <br></br>
            <a href="https://bluescope.applyeasy.com.au/orrcon/introduction" target="_blank">
              Online Account Credit Application process.
            </a>
          </p>
          <p>Instructions are available on the site to assist in completing the form, and its submission. If you'd like to assistance or speak to somebody prior to submitting an application, <a href="/store-locator">contact your local branch</a></p>
          <p><a href="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/2023-11/Credit%20Reporting%20Policy.pdf" target="_blank">
            Please read our Credit Reporting Policy
          </a></p>
        </Col>
      </Row>
    </Layout>
  )
}

export default CreditPage

export const Head = () => (
  <>
    <title>Credit Application | Orrcon Steel</title>
    <meta name="description" content="Online Account Credit Application process." />
    <meta name="og:description" property="og:description" content="Your One-Stop Shop for Effortless Ordering of Premium Steel Products. Buy online or call us today. At Orrcon Steel, we'll see it through" />
    <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon.png" />
  </>
)
